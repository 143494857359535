/** @jsx jsx */
import {
  jsx,
  Box,
  Button,
  Label,
  Heading,
  Text,
  Input,
  Textarea,
} from "theme-ui"
import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"

const Field = props => <Box sx={{ mb: 3 }} {...props} />

const ContactPage = () => (
  <Layout>
    <SEO title="Home" />
    <Box variant="layout.wrapper.small">
      <Heading as="h1">Get In Touch</Heading>
      <Text sx={{ pt: 3, pb: 4, fontSize: 3 }}>
        Got any business inquiries, questions, comments or concerns? Leave a
        message here and we’ll get back to you as soon as possible! Or email us
        at <a href="mailto:mail@qualitycheese.net">Quality Cheese</a>
      </Text>
      <form name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <Field>
          <Label htmlFor="name">Name</Label>
          <Input type="text" name="name" id="name" required />
        </Field>
        <Field>
          <Label htmlFor="email">Email</Label>
          <Input type="email" name="email" id="email" required />
        </Field>
        <Field>
          <Label htmlFor="message">Message</Label>
          <Textarea
            sx={{ fontFamily: "body" }}
            name="message"
            id="message"
            rows="6"
            required
          />
        </Field>
        <Field>
          <Button variant="form" type="submit">
            Send Message
          </Button>
        </Field>
      </form>
    </Box>
  </Layout>
)

export default ContactPage
