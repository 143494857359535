/** @jsx jsx */
import { jsx, Box, Heading, Flex, Text, MenuButton } from "theme-ui"
import { Link } from "gatsby"
import React, { useState } from "react"
import { Global, css } from "@emotion/core"

import navLinks from "../utils/navLinks"
import Footer from "../images/footer.png"

const MobileLinks = ({ setMenuOpen }) => (
  <Box
    onClick={() => setMenuOpen(false)}
    sx={{
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontSize: 6,
    width: "100%",
    height: "100%",
    bg: "navy",
    zIndex: 100,
    p: 3
  }}>
    {navLinks.map(({ name, to }) => (
      <Link key={name} sx={{
        variant: "links.default",
        display: "block",
        py: 3,
        color: "skyBlue",
        opacity: .9,
        transition: "opacity .2s, color .2s",
        "&:hover": {
          opacity: 1,
          color: "white"
        }
      }} to={to}>{name}</Link>
    ))}
  </Box>
)
const DeskLinks = () => (
  <Flex sx={{py: 3}}>
    {navLinks.map(({ name, to }) => (
      <Link
        key={name}
        sx={{
          variant: "links.default",
          px: [3, 3, 3, 3, 4],
          opacity: .9,
          transition: "opacity .2s, color .2s",
          "&:hover": {
            opacity: 1,
            color: "navy"
          }
        }}
        to={to}
        partiallyActive={to === "/" ? false : true}
        activeClassName="active"
      >{name}</Link>
    ))}
  </Flex>
)

const Logo = (props) => (
  <Link sx={{variant: "links.default"}} to="/"><Heading as="h1" sx={{fontSize: 3, fontWeight: "bold"}} {...props}>ADOPT AN ALP</Heading></Link>
)

const Layout = props => {
  const [menuOpen, setMenuOpen] = useState(false)
  const handleMenu = () => {
    setMenuOpen(!menuOpen)
  }
  return (
    <>
      {menuOpen && <MobileLinks setMenuOpen={setMenuOpen} />}
      <Box sx={{mb: [4, 4, 5], fontSize: [1, 1, 2], py: 2, borderBottom: "1px solid #eee", position: "sticky", top: "0", zIndex: 50, bg: "white"}}>
        <Box variant="layout.wrapper.large">
          <Flex sx={{justifyContent: "space-between", alignItems: "center"}}>
            <Logo sx={{pr: 4}} />
            <Box sx={{display: ["none", "none", "block"]}}>
              <DeskLinks />
            </Box>
            <Box sx={{display: ["block", "block", "none"]}}>
              <MenuButton sx={{color: "skyBlue"}} onClick={handleMenu} />
            </Box>
          </Flex>
        </Box>
      </Box>
      <Global
        styles={css`
          .caption {
            margin-bottom: 1rem;
          }
          .caption img {
            width: 100%;
          }
          iframe {
            display: block;
            margin: 0 auto 1rem auto;
          }
          p, br {
            display: block;
            margin-bottom: 1rem;
          }
          input[type="search"]::-webkit-search-decoration,
          input[type="search"]::-webkit-search-cancel-button,
          input[type="search"]::-webkit-search-results-button,
          input[type="search"]::-webkit-search-results-decoration {
            -webkit-appearance:none;
          }
        `}
      />
      <Box {...props} />
      <Box sx={{variant: "layout.wrapper.small", mt: 6, mb: 5}}>
        <Flex sx={{justifyContent: "center"}}><DeskLinks /></Flex>
        <Box sx={{textAlign: "center"}}>
          <Text>&copy; Copyright 2020 Adopt An Alp by <a href="https://qualitycheese.net">Quality Cheese Inc</a></Text>
        </Box>
      </Box>
      <img src={Footer} sx={{width: "100%"}} />
    </>
  )
}

export default Layout
